import * as React from "react"
import { Position, Toast, Toaster } from "@blueprintjs/core"

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export var detectIE = function () {
  var ua = window.navigator.userAgent

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf("MSIE ")
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10)
  }

  var trident = ua.indexOf("Trident/")
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:")
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10)
  }

  var edge = ua.indexOf("Edge/")
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10)
  }

  // other browser
  return false
}

export default class IEToast extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      browserStatus: true,
      browserName: ""
    }

    this.refHandlers = {
      toaster: ref => (this.toaster = ref)
    }

    this.addToast = toastMessage => {
      this.toaster.show({ message: toastMessage })
    }
  }

  componentDidMount() {
    // Get IE or Edge browser version
    var version = detectIE()

    if (version === false) {
      // is normal browser
      this.addToast(this.state.browserName)
      // console.log(this.state.browserName);
    } else if (version >= 12) {
      this.state.browserName = "Edge " + version
      this.addToast(this.state.browserName)
      // console.log(this.state.browserName);
    } else {
      this.state.browserName = "IE " + version
      this.state.browserStatus = false
      this.addToast(this.state.browserName)
      // console.log(this.state.browserName);
    }

    // showToast = () => {
    //     // create toasts in response to interactions.
    //     // in most cases, it's enough to simply create and forget (thanks to timeout).
    //     if (this.state.browserStatus) {
    //         AppToaster.show({ message: "Tosted. " + this.state.browserName });
    //     } else if (!this.state.browserStatus) {
    //         AppToaster.show({ message: "NotTosted. " + this.state.browserName });
    //     }
    // }
    // showToast();
  }

  render() {
    return <></>
  }
}
